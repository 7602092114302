export interface OffersChipsCounters {
  active: number;
  partners: number;
  draft: number;
  moderate: number;
  deleted: number;
  banned: number;
}

const CHIPS_COUNTERS = '@offers/chips-counters';

export interface ChipsCountersAction {
  type: typeof CHIPS_COUNTERS;
  payload: {
    chipsCounters: OffersChipsCounters;
  };
}

interface State {
  chipsCounters: OffersChipsCounters;
}

const initialState: State = {
  chipsCounters: {
    active: 0,
    partners: 0,
    draft: 0,
    moderate: 0,
    deleted: 0,
    banned: 0,
  },
};

export const chipsCountersReducer = (
  state: State = initialState,
  action: ChipsCountersAction,
) => {
  switch (action.type) {
    case CHIPS_COUNTERS:
      return {
        ...state,
        chipsCounters: action.payload.chipsCounters,
      };
    default:
      return state;
  }
};

export const setChipsCounters = (counters: OffersChipsCounters) => {
  return {
    type: CHIPS_COUNTERS,
    payload: {
      chipsCounters: counters,
    },
  };
};
