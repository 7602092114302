import {ChipsCounters} from 'common/types';

const CHIPS_COUNTERS = '@deals/chips-counters';

export interface ChipsCountersAction {
  type: typeof CHIPS_COUNTERS;
  payload: {
    chipsCounters: ChipsCounters;
  };
}

interface State {
  chipsCounters: ChipsCounters;
}

const initialState: State = {
  chipsCounters: {
    agencyAdminDeals: 0,
    rieltorDeals: 0,
    rieltorAgencyDeals: 0,
    rieltorPartnersDeals: 0,
    afnuDeals: 0,
    sfnuDeals: 0,
    globalDeals: 0,
  }
};

export const chipsCountersReducer = (
  state: State = initialState,
  action: ChipsCountersAction
) => {
  switch (action.type) {
    case CHIPS_COUNTERS:
      return {
        ...state,
        chipsCounters: action.payload.chipsCounters
      };
    default:
      return state;
  }
};

export const setChipsCounters = (counters: ChipsCounters) => {
  return {
    type: CHIPS_COUNTERS,
    payload: {
      chipsCounters: counters
    }
  };
};
