import {OffersManagementListing, OffersManagementListingItem} from 'common/types';

export const FETCH_OFFERS_MANAGEMENT_LISTING_REQUEST =
  '@offers/fetch-offers-management-request';
export const FETCH_OFFERS_MANAGEMENT_LISTING_SUCCESS =
  '@offers/fetch-offers-management-success';
export const FETCH_OFFERS_MANAGEMENT_LISTING_FAILURE =
  '@offers/fetch-offers-management-failure';

export const OFFER_SELECT = '@offers/select';

export interface OfferSelectAction {
  type: typeof OFFER_SELECT;
  payload: {
    offer: null|OffersManagementListingItem;
  };
}

export interface FetchOffersManagementListingAction {
  type: typeof FETCH_OFFERS_MANAGEMENT_LISTING_REQUEST;
  // @todo: add type
  payload: {
    params: any;
  };
}

export interface FetchOffersManagementListingSuccessAction {
  type: typeof FETCH_OFFERS_MANAGEMENT_LISTING_SUCCESS;
  payload: {
    data: OffersManagementListing;
  };
}

export interface FetchOffersManagementListingFailureAction {
  type: typeof FETCH_OFFERS_MANAGEMENT_LISTING_FAILURE;
  error: Error;
}

export type OffersAction =
  | OfferSelectAction
  | FetchOffersManagementListingAction
  | FetchOffersManagementListingSuccessAction
  | FetchOffersManagementListingFailureAction;
