import {
  Box,
  Container,
  Hidden,
  makeStyles,
} from '@material-ui/core';
import cx from 'classnames';
import UserMenu from 'components/UserMenu/index';
import React, {FunctionComponent, lazy, Suspense} from 'react';
import {Redirect, Switch} from 'react-router-dom';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import Footer from 'screens/common/Footer';
import Loading from 'screens/common/Loading';
import PrivateRoute from 'screens/common/PrivateRoute';
import {UserAlerts} from 'screens/common/UserAlerts';
import Header from 'screens/MainLayout/Header/index';
import MobilePanelBottom from 'screens/MainLayout/MobilePanelBottom';
import {rieltor} from 'styles/theme';
import {useSessionStorage} from 'react-use';
import {SessionStorageKey} from '../../common/types';

const OfferDetailsScreen = lazy(() =>
  import('screens/offers/OfferDetailsScreen')
);
const PaymentScreen = lazy(() => import('screens/PaymentScreen'));
const OlxImportScreen = lazy(() => import('screens/OlxImportScreen'));
const OlxMigrationScreen = lazy(() => import('screens/OlxMigrationScreen'));
const ReferralsScreen = lazy(() => import('screens/ReferralsScreen'));
const AgencyFeedScreen = lazy(() => import('screens/AgencyFeedScreen'));
// const DealsScreen = lazy(() => import('screens/DealsScreen'));
const DealsScreen = lazy(() => import('screens/DealsScreen/index_new'));
const OffersManagementScreen = lazy(() =>
  import('screens/offers/OffersManagement/index_new')
);

const ProfileIndexScreen = lazy(() =>
  import('screens/profile/ProfileIndexScreen')
);

const AgentsSuggestionsScreen = lazy(() =>
  import('screens/AgentsSuggestionsScreen')
);

const DashboardScreen = lazy(() =>
  import('screens/DashboardScreen')
);

const PartnersScreen = lazy(() =>
  import('screens/PartnersScreen')
);

const UnionsScreen = lazy(() =>
  import('screens/UnionsScreen')
);

const RieltorManifestScreen = lazy(() =>
  import('screens/RieltorManifestScreen')
);

const useStyles = makeStyles(theme => ({
  root: {
    [theme.breakpoints.down('xs')]: {
      paddingBottom: theme.spacing(6),
    },
  },
  content: {
    minHeight: 'calc(100vh - 160px)', // 100 % - header height
    overflow: 'hidden',
    position: 'relative',
  },
  newBg: {
    backgroundColor: rieltor.grayLines
  }
}));

export interface MainLayoutProps extends RouteComponentProps {}

const MainLayout: FunctionComponent<MainLayoutProps> = ({
  location: {pathname},
}: MainLayoutProps) => {
  const classes = useStyles();

  // FIXME: move bg style to global styles when all pages done
  const hasNewBackground = [
    '/dashboard',
    '/deals',
    '/partners',
    '/unions',
    '/profile/agents',
    '/offers/management',
    '/agency-feed',
  ].includes(pathname);

  // debugger;

  return (
    <Box className={classes.root}>
      <Header />
      <Box className={cx(classes.content, {[classes.newBg]: hasNewBackground})}>
        <Hidden smDown>
          <UserMenu/>
        </Hidden>

        <Container maxWidth={'xl'} style={{padding: '0 16px'}}>
          <UserAlerts />
        </Container>

        <Suspense fallback={<Loading />}>
          <Switch>
            <PrivateRoute
                path="/dashboard"
                exact
                component={DashboardScreen}
            />
            <PrivateRoute
              path="/offers/management"
              exact
              component={OffersManagementScreen}
            />
            <PrivateRoute
              path="/offers/create/page-:pageId?"
              exact
              component={OfferDetailsScreen}
            />
            <PrivateRoute
              path="/offers/create/agency-:feedId?"
              exact
              component={OfferDetailsScreen}
            />
            <PrivateRoute
              path="/offers/create"
              component={OfferDetailsScreen}
            />
            <PrivateRoute
              path="/offers/edit/:offerId"
              component={OfferDetailsScreen}
            />
            <PrivateRoute path="/payment" exact component={PaymentScreen} />
            <PrivateRoute path="/deals" exact component={DealsScreen} />
            <PrivateRoute path="/internet-offers" exact component={OlxImportScreen} />
            <PrivateRoute path="/agency-feed" exact component={AgencyFeedScreen} />

            <PrivateRoute
              path="/agents-suggestions/:offerId"
              exact
              component={AgentsSuggestionsScreen}
            />
            <PrivateRoute path="/profile" component={ProfileIndexScreen} />
            <PrivateRoute path="/referrals" component={ReferralsScreen} />

            <PrivateRoute path="/olx-migration" component={OlxMigrationScreen} />
            <PrivateRoute path="/partners" component={PartnersScreen} />
            <PrivateRoute path="/unions" component={UnionsScreen} />
            <PrivateRoute path="/rieltor-manifest" component={RieltorManifestScreen} />

          </Switch>
        </Suspense>
      </Box>

      <Hidden xsDown>
        <Footer />
      </Hidden>

      <Hidden mdUp>
        <MobilePanelBottom pathname={pathname}/>
      </Hidden>
    </Box>
  );
};

export default withRouter(MainLayout);
