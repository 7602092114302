import {
  ExclusiveDocumentInfo,
  MultiplePickupInfo,
  OfferAction,
  OfferAgentsSuggestionsInfo,
  OffersListingSearch,
  OffersManagementFields,
  OffersManagementListingItem,
  Pickup,
  PickupCoins,
  PickupOptions, PickupPlace,
  ReasonOfUnpublish,
  RoleId,
  TariffEntity,
  CalcCashbackResult, OfferCopyResponse, APIResponse, OfferReportResult,
} from 'common/types';
import {apiMethod, APIService} from 'services/APIService';
import {createCRUDAPIService} from 'services/CRUDAPIService';
import {OffersChipsCounters} from '../../store/offers/reducers/chipsCounters';
import {CommonValueLabel} from '../../components/Table/TableSelect';

interface ImageUploadInfo {
  imageKey: string;
  imageId: number;
}

interface PickupCoinsParams {
  id: number;
  place: number;
  cityId?: number;
  itemType?: number;
  operType?: number;
}
interface PickupPlaceParams {
  id: number;
  coins: number;
  cityId?: number;
  itemType?: number;
  operType?: number;
  quickly?: number;
  autoupdate?: number;
  birdPremium?: number;
  lunTop?: number;
}

interface CalcCashbackParams {
  id: number;
  rate: number;
  cityId?: number;
  itemType?: number;
  operType?: number;
  quickly?: number;
  autoupdate?: number;
}

interface TariffInfoParams {
  cityId?: number;
  offerId?: number;
}

export interface DelayPickupParams {
  itemId: number;
  paymentId?: number;
  tariffId: string;
  rate?: number;
  quickly: number;
}

interface SetTariffParams {
  tariffId: string;
  rate?: number;
  quickly: number;
}

interface FetchUnpublishReasonsPayload {
  roleId: RoleId;
}

export type CreateHousingCostCalculationRequestPayload = {
  cityId: number;
  itemType: string;
  address: string;
  area: number;
  photos: number[];
  phone: string;
};

export interface SmallOffersListing {
  fields: OffersManagementFields;
  items: OffersManagementListingItem[];
}

export class OffersAPI extends APIService {
  create = apiMethod((data: object) =>
    this.fetcher.request({
      url: '/api/offers/add/',
      method: 'post',
      data,
    })
  );

  uploadImage = apiMethod<ImageUploadInfo>((file: File) => {
    const formData = new FormData();
    formData.append('file', file);

    return this.fetcher.request({
      url: '/api/offers/upload-photo/',
      method: 'post',
      data: formData,
    });
  });

  uploadPhotoForHousingCostCalculationRequest = apiMethod<ImageUploadInfo>(
    (file: File) => {
      const formData = new FormData();
      formData.append('file', file);

      return this.fetcher.request({
        url: '/api/apartment-sell-request/upload-photo/',
        method: 'post',
        data: formData,
      });
    }
  );

  uploadExclusiveDocument = apiMethod<ExclusiveDocumentInfo>((file: File, params: {}) => {
    const formData = new FormData();
    formData.append('file', file);

    return this.fetcher.request({
      url: '/api/offers/upload-exclusive-docs/',
      method: 'post',
      data: formData,
      params,
    });
  });

  fetchOffersList = apiMethod((params: Partial<OffersListingSearch>) =>
    this.fetcher.request({
      method: 'get',
      url: '/api/offers/list/',
      params,
    })
  );

  smallOffersFetcher = apiMethod<SmallOffersListing>(() =>
    this.fetcher.request({
      method: 'get',
      url: '/api/offers/small-list/',
    })
  );

  applyAction = apiMethod(
    (
      action: OfferAction,
      offersIds: number[],
      reason?: number | string,
      userPrice?: number
    ) =>
      this.fetcher.request({
        method: 'get',
        url: '/api/offers/item-action/',
        params: {
          id: offersIds.join(','),
          action,
          reason,
          userPrice,
        },
      })
  );

  fetchReasonsOfUnpublish = apiMethod<ReasonOfUnpublish[]>(
    (payload: FetchUnpublishReasonsPayload) =>
      this.fetcher.request({
        url: '/api/common/permission-reasons/',
        params: {
          groupId: payload.roleId,
        },
      })
  );

  fetchAgentsSuggestions = apiMethod<OfferAgentsSuggestionsInfo>(
    (offerId: number) =>
      this.fetcher.request({
        method: 'get',
        url: `/api/offers/suggest-rieltors/${offerId}/`,
      })
  );
  fetchMultipleItemsCost = apiMethod<number>((offersIds: number[]) =>
    this.fetcher.request({
      method: 'get',
      url: '/api/offers/item-action/',
      params: {
        id: offersIds.join(','),
        action: 'calc',
      },
    })
  );

  createHousingCostCalculationRequest = apiMethod(
    (payload: CreateHousingCostCalculationRequestPayload) => {
      return this.fetcher.request({
        method: 'post',
        url: `/api/apartment-sell-request/create/`,

        data: payload,
      });
    }
  );

  createOfferCopy = apiMethod<OfferCopyResponse>(
    (id: number) => this.fetcher.request({
      method: 'post',
      url: '/api/offers/copy-item/',
      data: {
        id
      }
    }),
    {
      throwIfStatusFail: false
    }
  );

  getReport = apiMethod<OfferReportResult>((id: number) =>
    this.fetcher.request({
      method: 'get',
      url: `/api/offers/report/${id}/`,
    })
  );

  shareReport = apiMethod((id: number) =>
    this.fetcher.request({
      method: 'put',
      url: `/api/offers/report/${id}/`,
    })
  );

  getChipsCounters = apiMethod<OffersChipsCounters>(
    (params: Partial<OffersListingSearch>) =>
      this.fetcher.request({
        method: 'GET',
        url: '/api/offers/chips-counters/',
        params,
      }),
  );

  getCitiesFilter = apiMethod<CommonValueLabel[]>(
    (params: Partial<OffersListingSearch>) =>
      this.fetcher.request({
        method: 'GET',
        url: '/api/offers/cities-filter/',
        params,
      }),
  );
}

export const OfferCRUDAPI = createCRUDAPIService('/api/offers');

export const PickupItemCRUDAPI = createCRUDAPIService<Pickup>(
  '/api/offers/pickup-item'
);

export class PickupItemAPI extends PickupItemCRUDAPI {
  testPickup = apiMethod<PickupOptions>((params: PickupCoinsParams) =>
    this.fetcher.request({
      method: 'get',
      url: `/api/offers/pickup-item/${params.id}/`,
      params,
    })
  );
  delayPickup = apiMethod((params: DelayPickupParams) =>
    this.fetcher.request({
      method: 'put',
      url: `/api/offers/delay-pickup/${params.itemId}/`,
      data: params,
    })
  );
  getTariffs = apiMethod<TariffEntity[]>((params: TariffInfoParams) =>
    this.fetcher.request({
      method: 'get',
      url: '/api/offers/get-tariffs/',
      params,
    })
  );
  setTariff = apiMethod((params: DelayPickupParams) =>
    this.fetcher.request({
      method: 'put',
      url: `/api/offers/set-tariff/${params.itemId}/`,
      data: params,
    })
  );
  getPickupCoins = apiMethod<PickupCoins>((params: PickupCoinsParams) =>
    this.fetcher.request({
      method: 'get',
      url: '/api/offers/pickup-coins/',
      params,
    })
  );
  getPickupPlace = apiMethod<PickupPlace>((params: PickupPlaceParams) =>
    this.fetcher.request({
      method: 'get',
      url: '/api/offers/pickup-place/',
      params,
    })
  );

  fetchMultiplePickupInfo = apiMethod<MultiplePickupInfo>(
    (offersIds: number[]) => {
      const ids = encodeURIComponent(offersIds.join(','));

      return this.fetcher.request({
        method: 'get',
        url: `/api/offers/multiple-pickup-item/${ids}/`,
      });
    }
  );

  applyMultiplePickup = apiMethod((offersIds: number[]) => {
    return this.fetcher.request({
      method: 'post',
      url: `/api/offers/multiple-pickup-item/`,
      data: {
        id: offersIds,
      },
    });
  });

  calcCashback = apiMethod<CalcCashbackResult>((params: CalcCashbackParams) =>
    this.fetcher.request({
      method: 'get',
      url: `/api/offers/calc-cashback/${params.id}/`,
      params,
    })
  );
}
