import {combineReducers} from 'redux';
import {actionDialogsReducer} from './actionDialogs';
import {chipsCountersReducer} from './chipsCounters';
import {managementReducer} from './management';

export const offersReducer = combineReducers({
  management: managementReducer,
  chipsCounters: chipsCountersReducer,
  dialogs: actionDialogsReducer,
});
