export type Nullable<T> = T | null;

export type LatLng = {
  lat: number;
  lng: number;
};

export enum OperType {
  Sale = 1,
  Rent = 2,
  DailyRent = 3,
}

export enum ItemType {
  Flats = 1,
  Rooms = 2,
  Houses = 3,
  Commercial = 4,
  Land = 5,
}

export enum OlxOperType {
  Sale = 1,
  Rent = 2,
}

export enum OlxItemType {
  Flats = 1,
  Houses = 3,
}

export enum GeneralYesNo {
  Yes = 'yes',
  No = 'no',
}

export enum CityId {
  Kyiv = 891,
  Kharkiv = 2211,
  Lviv = 1198,
  Odessa = 1523,
  Dnipro = 601,
  Chernihiv = 2290,
  Zaporizhzhia = 9601,
  Poltava = 1722
}


export enum RegionId {
  Kyiv = 111,
}

export enum PremiumType {
  Premium = 1,
  Urgent = 2,
  Torg = 3,
  Hot = 4,
  Action = 5,
  Profitable = 6,
  Elite = 7,
}

export enum CurrencyMagic {
  UAH = 1,
  USD = 2,
  EUR = 3,
}

export enum APIResponseStatus {
  Ok = 'OK',
  Error = 'ERROR',
}

export enum APIResponseErrorType {
  UserDeleted = 'user_deleted',
  PhoneNotFound = 'phone_not_found',
  CustomError = 'custom_error',
  PhoneNotValid = 'phone_not_valid',
  CodeNotValid = 'code_not_valid',
  PhoneAlreadyRegistered = 'phone_already_registered',
}

export interface APIResponseOk<T> {
  status: APIResponseStatus.Ok;
  data: T;
  paymentId: number;
}

export interface APIResponseError {
  status: APIResponseStatus.Error;
  error: APIResponseErrorType;
  message?: string;
}

export type APIResponse<T> = APIResponseOk<T> | APIResponseError;

export class APIError extends Error {
  constructor(message: string, public errorType: APIResponseErrorType) {
    super(message);

    Object.setPrototypeOf(this, APIError.prototype);
  }
}

export interface OfferCopyResponse {
  isFreePublish: boolean;
  id: number;
}

export enum Locale {
  UK = 'uk',
  RU = 'ru',
}

export enum LocaleFull {
  UK = 'uk-UA',
  RU = 'ru-RU',
}

export const MAP_LOCALE_FULL_TO_LOCALE = {
  [LocaleFull.RU]: Locale.RU,
  [LocaleFull.UK]: Locale.UK,
};

export const MAP_LOCALE_TO_LOCALE_FULL = {
  [Locale.RU]: LocaleFull.RU,
  [Locale.UK]: LocaleFull.UK,
};

export enum CookieName {
  Locale = 'language',
}

export const DEFAULT_LOCALE = Locale.UK;
export const DEFAULT_LOCALE_FULL = LocaleFull.UK;

export interface AutocompleteCitiesItem {
  cityId: number;
  name: string;
  description: string;
}

export interface AutocompleteStreetsItem {
  streetId: number;
  name: string;
}

export interface Spec {
  id: string;
  itemType: number;
  operType: number;
  name: string;
}

export enum Header {
  AcceptLanguage = 'Accept-Language',
}

export enum QueryParam {
  RegisterId = 'registerId',
  Phone = 'phone',
  To = 'to',
  AlertType = 'alertType',
  AlertPayload = 'alertPayload',
  AlertPayload2 = 'alertPayload2',
  OfferAction = 'offerAction',
  OfferActionIds = 'offerActionIds',
  VerifyBankid = 'verifyBankid',
  VerifyDiia = 'verifyDiia',
  Verification = 'verification',
  Referral = 'referral',
  LoyalCard = 'loyalCard',
}

export enum UserVirification {
  Bankid = 'bankid',
  Diia = 'diia',
}

export enum DiiaAuthStatus {
  AnotherUsed = 'another_used',
  Success = 'success',
  Error = 'error',
  Pending = 'pending',
  UserDeleted = 'user_deleted',
  WrongRenewData = 'wrong_renew_data',
  //verification-not-match
  VerificationNotMatch = 'verification-not-match',
}

export enum UserType {
  Owner = 'owner',
  Realtor = 'realtor',
  Agency = 'agency',
}

export const FAKE_HTML_ATTR_AUTOCOMPLETE_VALUE = 'donotcomplete';

export interface Option<ValueT = any> {
  value: ValueT;
  label: any;
  startIcon?: any;
  endIcon?: any;
}

export enum PremiumGroupTitles {
  About = 'about',
  Infrastructure = 'infrastructure',
  Details = 'details',
  Plan = 'plan',
  InputGroup = 'inputGroup',
}
export enum DetailParam {
  AddBuilding = 'addBuilding',
  AreaDescr = 'areaDescr',
  BalconyCnt = 'balconyCnt',
  BalconyType = 'balconyType',
  Basement = 'basement',
  BasementType = 'basementType',
  CeilHeight = 'ceilHeight',
  CeilType = 'ceilType',
  ColdWater = 'coldWater',
  ColdWaterType = 'coldWaterType',
  CommBuilding = 'commBuilding',
  DirDocumentId = 'dirDocumentId',
  DistanceToCity = 'distanceToCity',
  DistanceToSeacost = 'distanceToSeacost',
  DoorsState = 'doorsState',
  DoorsType = 'doorsType',
  Electricity = 'electricity',
  ElectricityDistance = 'electricityDistance',
  ElectricityType = 'electricityType',
  Entrance = 'entrance',
  FamilyCnt = 'familyCnt',
  Fence = 'fence',
  FenceType = 'fenceType',
  Flags = 'flags',
  Floor = 'floor',
  Floorheating = 'floorheating',
  FloorCnt = 'floorCnt',
  FloorState = 'floorState',
  FloorType = 'floorType',
  FrontdoorType = 'frontdoorType',
  Gas = 'gas',
  GasType = 'gasType',
  Heater = 'heater',
  HeaterType = 'heaterType',
  HighwayDistance = 'highwayDistance',
  HotWater = 'hotWater',
  HotWaterType = 'hotWaterType',
  HouseFoundation = 'houseFoundation',
  HouseMaterial = 'houseMaterial',
  KitchenRange = 'kitchenRange',
  KitchenType = 'kitchenType',
  Loading = 'loading',
  LoggiaCnt = 'loggiaCnt',
  LoggiaType = 'loggiaType',
  NearbyBuilding = 'nearbyBuilding',
  OverlapMaterialType = 'overlapMaterialType',
  Ownership = 'ownership',
  OwnersCnt = 'ownersCnt',
  OwnersPart = 'ownersPart',
  Parking = 'parking',
  ParkingType = 'parkingType',
  Phone = 'phone',
  PhoneCnt = 'phoneCnt',
  PhoneType = 'phoneType',
  Planning = 'planning',
  Plumber = 'plumber',
  PlumberState = 'plumberState',
  PowerCapacity = 'powerCapacity',
  Readiness = 'readiness',
  RegisteredCnt = 'registeredCnt',
  ReleaseCond = 'releaseCond',
  Roof = 'roof',
  RoofType = 'roofType',
  Safety = 'safety',
  Sewerage = 'sewerage',
  StairwayType = 'stairwayType',
  Tambour = 'tambour',
  TambourType = 'tambourType',
  Toilet = 'toilet',
  ToiletCnt = 'toiletCnt',
  ToiletType = 'toiletType',
  Tubes = 'tubes',
  TubesState = 'tubesState',
  TubesType = 'tubesType',
  Type = 'type',
  WallsType = 'wallsType',
  WindowsCnt = 'windowsCnt',
  WindowsState = 'windowsState',
  WindowsType = 'windowsType',
  WindowsView = 'windowsView',
  Year = 'year',
  Internet = 'internet',
  InternetType = 'internetType',
  AutonomousElectricity = 'autonomousElectricity',
  AutonomousElectricityType = 'autonomousElectricityType',
  Eoselia = 'detailParams.eoselia',
}
export enum OfferProperty {
  GroupTitle = 'groupTitle',
  ItemType = 'itemType',
  OperType = 'operType',
  Status = 'status',

  Price = 'price',
  CurrencyMagic = 'currencyMagic',
  WithoutCommission = 'withoutCommission',
  AllowAssignment = 'allowAssigment',
  CommissionRate = 'commissionRate',
  CommissionType = 'commissionType',
  PayCommission = 'payCommission',
  CanEditAddress = 'canEditAddress',
  Rooms = 'rooms',
  RoomCount = 'roomCnt',
  CommonArea = 'commonArea',
  UsefulArea = 'usefulArea',
  KitchenArea = 'kitchenArea',
  GroundArea = 'groundArea',
  PlanningType = 'planningType',
  CommonState = 'commonState',
  HouseType = 'houseType',
  BuildingType = 'buildingType',
  BuildingKind = 'buildingKind',
  Commissioning = 'commissioning',
  Floor = 'floor',
  FloorCount = 'floorCnt',
  YearsCount = 'yearsCount',
  Grade = 'grade',
  Description = 'description',
  SpecFlags = 'specFlags',
  Furniture = 'furniture',
  Aim = 'aim',
  ChangeTerms = 'changeTerms',

  // exclusive
  IsExclusive = 'isExclusive',
  ExclusiveIsVerified = 'exclusiveIsVerified',
  Expiration = 'expiration',
  ExclusiveFlatNumber = 'exclusiveFlatNumber',
  ExclusiveInn = 'exclusiveInn',
  ExclusiveRegNum = 'exclusiveRegNumber',
  ExclusiveFiles = 'exclusiveFiles',
  ExclusiveRegistryFiles = 'exclusiveRegistryFiles',

  // User can add note to offer, visible only for him
  Note = 'note',

  UserInn = 'userInn',

  // photos
  VideoLink = 'videoLink',
  RentaImages = 'rentaImages',

  // geo

  NewHouse = 'newhouse',
  NewHouseId = 'newhouseId',
  NewHouseNameUnfound = 'newhouseNameUnfound',

  Cottage = 'cottage',
  CottageId = 'cottageId',

  Region = 'region',
  RegionId = 'regionId',

  City = 'city',
  CityId = 'cityId',

  District = 'district',
  DistrictId = 'districtId',

  Orients = 'orients',

  Subways = 'subways',

  Street = 'street',
  StreetNameUnfound = 'streetNameUnfound',
  StreetId = 'streetId',

  House = 'house', // @todo: not used, remove it
  HouseId = 'houseId', // @todo: not used, remove it
  HouseNameUnfound = 'houseNameUnfound',
  CadastralNumber = 'cadastralNumber',
  CadastralPolygon = 'cadastralPolygon',

  Latitude = 'latitude',
  Longitude = 'longitude',

  // for internal usage
  Position = 'position',

  AllowChildren = 'allowChildren',
  AllowPets = 'allowPets',

  IsRentingForShortPeriodAvailable = 'isRentingForShortPeriodAvailable',

  PickupRate = 'pickupRate',
  PickupQuickly = 'pickupQuickly',
  PickupAutoupdate = 'pickupAutoupdate',
  PickupBirdPremium = 'pickupBirdPremium',
  PickupLunTop = 'pickupLunTop',
  LunTop = 'lunTop',

  TariffId = 'tariffId',

  TechInfo = 'techInfo',
  Approve = 'approve',

  // detail params — stored in DB
}

export const OFFER_GEO_PROPERTIES_ORDER = [
  OfferProperty.NewHouse,
  OfferProperty.Region,
  OfferProperty.City,
  OfferProperty.District,
  OfferProperty.Street,
  OfferProperty.HouseNameUnfound,
];

interface OfferPropertyBaseData {
  paramKey: string;
  label: string;
  dependOn: Nullable<string>;
  dependOnDisabledValue: Nullable<number>;
  dependOnEnabledValue: Nullable<number>;
  required: boolean;
  disabled?: boolean;
}

export enum OfferPropertyType {
  Text = 'text',
  Radio = 'radio',
  Select = 'select',
  MultipleSelect = 'multiple_select',
}

export interface OfferPropertyTextData extends OfferPropertyBaseData {
  type: OfferPropertyType.Text;
  isGeneral: boolean;
}

export interface OfferPropertyRadioData extends OfferPropertyBaseData {
  type: OfferPropertyType.Radio;
  options: Option[];
  isGeneral: boolean;
}

export interface OfferPropertySelectData extends OfferPropertyBaseData {
  type: OfferPropertyType.Select;
  options: Option[];
  isGeneral: boolean;
}

export interface OfferPropertyMultipleSelectData extends OfferPropertyBaseData {
  type: OfferPropertyType.MultipleSelect;
  options: Option[];
  isGeneral: boolean;
}

// use for details properties
export type OfferPropertyData =
  | OfferPropertyTextData
  | OfferPropertyRadioData
  | OfferPropertySelectData
  | OfferPropertyMultipleSelectData;

export enum Listing {
  Cities = 'Cities',
  Districts = 'Districts',
  FinanceOperations = 'FinanceOperations',
  ItemTypes = 'ItemTypes',
  NewBuildings = 'NewBuildings',
  Cottages = 'Cottages',
  OffersManagement = 'OffersManagement',
  OperTypes = 'OperTypes',
  Orients = 'Orients',
  Subways = 'Subways',
  Regions = 'Regions',
  Streets = 'Streets',
  Novaposhta = 'Novaposhta',
  UserAgencies = 'UserAgencies',
  UserPhones = 'UserPhones',
}

export interface District {
  districtId: number;
  cityId: number;
  name: string;
  hasGeoJson: boolean;
}

export interface IdValueItem {
  id: number;
  value: string;
}

export interface NewBuilding {
  novostroyId: number;
  url: string;
  value: string;
  region: IdValueItem;
  city: IdValueItem;
  district: IdValueItem;
  street: IdValueItem;
  orient: IdValueItem;
  subway: IdValueItem;
  lat: number;
  lng: number;
}
export interface Cottage {
  cottageId: number;
  url: string;
  value: string;
  region: IdValueItem;
  city: IdValueItem;
  district: IdValueItem;
  street: IdValueItem;
  orient: IdValueItem;
  subway: IdValueItem;
}

export interface Region {
  regionId: number;
  name: string;
}

export interface Orient {
  orientId: number;
  name: string;
}

export interface Subway {
  subwayId: number;
  name: string;
}

export interface Street {
  streetId: number;
  name: string;
  description?: string;
}

export interface Novaposhta {
  id: number;
  name: string;
}

export interface OfferCommonFields {
  common: OfferPropertyData[];
  details: OfferPropertyData[];
}

export interface ExclusiveDocumentInfo {
  fileName: string;
  // add concrete format
  fileFormat: string;
  mime: string;
  userFileName: string;
  thumb: string;
}

export interface Phone {
  id: number;
  fullnumber: number;
  hasViber: boolean;
  hasTelegram: boolean;
  isConfirmed: boolean;
  isStatic: boolean;
  isMain: boolean;
  isContact: boolean;
}

export enum UserFirmStatus {
  Linked = 10,
  Requested = 5,
  Denied = -5,
}
export enum PartnerStatus {
  Linked = 10,
  Requested = 5,
  Denied = -5,
}

export interface UserFirm {
  id: number;
  name: string;
  shortname: string;
  status: UserFirmStatus;
  statusName: string;
  office: CommonValueLabel;
}

export interface RieltorPartner {
  id: number;
  name: string;
  status: PartnerStatus;
  statusName: string;
  isInitiator: boolean;
}

export enum RoleId {
  Owner = 4,
  Realtor = 8,
  AgencyAdmin = 32,
  Union = 64, // AFNU - SFNU
}

export const RoleName: any = {
  [RoleId.Owner] : 'owner',
  [RoleId.Realtor] : 'rieltor',
  [RoleId.AgencyAdmin] : 'agency',
}

export enum UserLevelStatus {
  White = 1,
  Silver = 2,
  Gold = 3,
  Platinum = 4
}

export type PaymentBonus = {
  payment: number,
  bonus: number,
  oldBonus: number,
}

export type UserLevelStatusSum = {
  [K in UserLevelStatus] : PaymentBonus
}

export interface ITextValue {
  title: string;
  value: number;
}

export enum UserSettingsField {
  DisplaySoldOffers = 'displaySoldOffers',
  DisablePartnerMessaging = 'disablePartnerMessaging',
}

export interface UserSettings {
  displaySoldOffers: boolean;
  disablePartnerMessaging: boolean;
}

export interface UserInfo {
  abSegments: { [key: string]: string };
  userId: number;
  address: Nullable<string>;
  fullAddress: string;
  phones: Phone[];
  // only for role=32
  isAgencyConfirmed: boolean;
  username: string;
  firstName: string;
  cityId: number;
  regionId: number;
  createdAt: string;
  tariffKey: string;
  city: string;
  region: string;
  email: string;
  role: string;
  roleId: RoleId;
  verificated: boolean;
  bankid: boolean;
  diia: boolean;
  failedBankid: number;
  failedDiia: number;
  isActivated: boolean;
  balance: number;
  avatar: string;
  domain: string;
  userFirm: Nullable<UserFirm>;
  rieltorPartner: Nullable<RieltorPartner>;

  skype: Nullable<string>;
  website: Nullable<string>;
  points: number;
  userExpenses: number;

  messagesCount: number;
  offersCount: number;
  activeOffersCount: number;
  dealsCount: number;
  internetOffers?: number;
  agencyFeed?: number;
  partnersOfferCount: number;

  freeOfferLimit: number;
  freeOfferLeft: number;

  hasNewRieltorBonus?: boolean;
  minRentaRate: number;

  viewCount: number;
  commentCount: number;

  about?: string;
  speakEnglish?: boolean;
  specializations: Array<{itemType: number; operType: number; name: string}>;
  coinsReserve: number;
  coinsPerDay: number;
  coinsLastActiveDay: number;

  agentsRequests?: number;
  partnersRequests?: number;
  partnersConfirmed?: number;

  startedAt?: string;

  isPersonOnAvatar?: boolean;
  freeOffersAccepted: Nullable<boolean>;
  futureOfferLimit: number;

  paymentContractLink: string;
  paymentContractProvider: string;
  paymentMainFop: string;
  paymentContractLinkStripe: string;
  minStripeAmount: number;
  minPaymentAmount: number;

  hasDeals: boolean;

  paymentBonus: any;
  levelStatus: UserLevelStatus;
  levelStatusName: string;
  levelStatusAlert: string;
  totalSum: number;
  userLevels: UserLevelStatusSum;
  allowShare: boolean;
  settings: UserSettings;

  dashboardExperience?: ITextValue;
  onSite: ITextValue;
  sfnu?: boolean;
  asnu?: boolean;
  avgDeals: string;

  freeOffersFields: string[];
  referralLink?: string;
  referralCount?: number;
  referralRating?: number;

  cityLunLink: string;
  cityFlatfyLink: string;

  alerts: UserCustomAlert[];
  alertStatusList: AlertStatusList;
  footer: string;
  hasViber: boolean;
  hasTelegram: boolean;

  sendMessage: number;
  contactClicks: number;
  anStyles?: AnStyles;

  isVolunteer?: boolean;

  firmBalance: number;
  firmCoinsPerDay: number;
  firmOffices: number;
  firmOfficesOthers: number;
  firmRieltors: number;
  rieltorsWithLowCoins?: number;

  freeOffersSuperRule?: boolean;
  freeOffersSuperRuleFailCondition?: FreeOffersSuperRuleFailCondition[];

  inn: string;
  revokedBankid?: boolean;

  hasBirdPremium: boolean;
  hasLunTop: boolean;
}

export enum FreeOffersSuperRuleFailCondition {
  Viber = 'viber',
  Verified = 'verified',
}

export interface UserCustomAlert {
  text: string;
  color: string;
  gradient: string;
  id: string;
  alertType: string;
  type?: 'success' | 'info' | 'warning' | 'error';
}

export interface AlertStatusList {
  [key: string]: {
    firstView?: number;
    isManuallyClosed?: boolean;
  };
}

export interface AlertStatusPostData {
  id: string;
  firstView?: boolean;
  isManuallyClosed?: boolean;
}

export interface Pagination {
  total: number;
  page: number;
  pageCount: number;
  limit: number;
}

export enum AgentsManagementField {
  Verificated = 'verificated',
  OffersCount = 'offersCount',
  ViewCount = 'viewCount',
  ContactClicks = 'contactClicks',
  SendMessage = 'sendMessage',
  CoinsPerDay = 'coinsPerDay',
  CoinsLastActivity = 'coinsLastActivity',
  UserExpenses = 'userExpenses',
  Balance = 'balance',
  BalanceDaysLeft = 'balanceDaysLeft',
}

export enum PartnersManagementField {
  Username = 'username',
  City = 'city',
  Verificated = 'verificated',
  OffersCount = 'offersCount',
  PartnersOffersCount = 'partnersOffersCount',
  DealsCount = 'dealsCount',
}
export const UserLevelStatusName:any = {
  1: 'White',
  2: 'Silver',
  3: 'Gold',
  4: 'Platinum'
}

export enum PaymentProvider {
  Liqpay = 'liqpay',
  Stripe = 'stripe',
  Monobank = 'monobank',
}

export enum ReferralsManagementField {
  Username = 'username',
  Avatar = 'avatar',
  CreatedAt = 'createdAt',
  Bankid = 'bankid',
  Specialist = 'specialist',
  Points = 'points',
  UserId = 'userId',
}

export enum OffersManagementField {
  Address = 'address',
  Area = 'area',
  AreaPrice = 'areaPrice',
  CommonArea = 'commonArea',
  Autoupdate = 'autoupdate',
  CityName = 'cityName',
  DistrictName = 'districtName',
  CityPos = 'cityPos',
  RegionPos = 'regionPos',
  CreatedAt = 'createdAt',
  DailyCost = 'dailyCost',
  Exclusive = 'exclusive',
  ExclusiveComment = 'exclusiveComment',
  ExclusiveStatus = 'exclusiveStatus',
  ExclusiveVerifiedStatus = 'exclusiveVerifiedStatus',
  ExclusiveIsVerified = 'exclusiveIsVerified',
  Floor = 'floor',
  Id = 'id',
  MainImage = 'mainImage',
  OfferUrl = 'offerUrl',
  Oper = 'oper',
  OperId = 'operId',
  OperTypeId = 'operTypeId',
  ItemType = 'itemType',
  ItemTypeId = 'itemTypeId',
  Orient = 'orient',
  Subway = 'subway',
  Price = 'price',
  PayCommission = 'payCommission',
  RawPrice = 'rawPrice',
  RawCurrency = 'rawCurrency',
  Rate = 'rate',
  FlatfyUrl = 'flatfyUrl',
  BirdSelect = 'birdSelect',
  LunUrl = 'lunUrl',
  BirdUrl = 'birdUrl',
  LunTopUrl = 'lunTopUrl',
  Representability = 'representability',
  Rooms = 'rooms',
  Status = 'status',
  StatusId = 'statusId',
  UpdatedAt = 'updatedAt',
  LastStatusChange = 'lastStatusChange',
  HasCopyThisMonth = 'hasCopyThisMonth',
  ViewAll = 'viewAll',
  ViewToday = 'viewToday',
  ViewYesterday = 'viewYesterday',
  Report = 'report',
  DiffDays = 'diffDays',
  TotalPickupSum = 'totalPickupSum',
  ItemCreatedAt = 'itemCreatedAt',
  StatusRemark = 'statusRemark',
  PremiumLabel = 'premiumLabel',

  UserId = 'userId',
  UserInfo = 'userInfo',
  Rieltor = 'rieltor',
  Office = 'office',

  UserPrice = 'userPrice',
  Auction = 'auction',
  HasPublishRestrictions = 'hasPublishRestrictions',
  WasPublished = 'wasPublished',
  Note = 'note',
}

export enum OlxImportManagementField {
  Address = 'address',
  Area = 'area',
  CommonArea = 'commonArea',
  Autoupdate = 'autoupdate',
  CityName = 'cityName',
  District = 'district',
  CreatedAt = 'createdAt',
  Floor = 'floor',
  Id = 'id',
  MainImage = 'mainImage',
  Url = 'url',
  Oper = 'oper',
  ItemType = 'itemType',
  Price = 'price',
  RawPrice = 'rawPrice',
  RawCurrency = 'rawCurrency',
  Rooms = 'rooms',
  IsOwner = 'isOwner',
  HasRieltor = 'hasRieltor',
}
export enum AgencyFeedManagementField {
  Address = 'address',
  Area = 'area',
  CommonArea = 'commonArea',
  Autoupdate = 'autoupdate',
  CityName = 'cityName',
  District = 'district',
  CreatedAt = 'createdAt',
  Floor = 'floor',
  FloorCount = 'floorCnt',
  Id = 'id',
  Fingerprint = 'fingerprint',
  MainImage = 'mainImage',
  Preview = 'preview',
  Url = 'url',
  Oper = 'oper',
  ItemType = 'itemType',
  Price = 'price',
  RawPrice = 'rawPrice',
  RawCurrency = 'rawCurrency',
  Rooms = 'rooms',
  IsOwner = 'isOwner',
  HasRieltor = 'hasRieltor',
}

export enum UserTariffRegion {
  Kiev = 'kiev',
  Lviv = 'lviv',
  KievRegion = 'ko',
  Top = 'top',
  Regions = 'regions',
}

export type NewRieltorBonus = {
  bonus: number;
  date: string;
}

export type NewRieltorBonusMap = {
  [key in string]: NewRieltorBonus
}

export type PaymentBonusMap = {
  [K in UserLevelStatus]: {
    [key in string]: {
      [key in number] : number
    };
  }
}

export type ReferralProgram = {
  payments: boolean;
  register: boolean;
  active: boolean;
  registerBonus: number;
  paymentBonus: number;
}

export type OlxImportProgram = {
  allowed: boolean;
}
export type OffersGlobalConfig = {
  imageUpload: OffersImageUploadConfig;
}
export type OffersImageUploadConfig = {
  maxSize: number;
  allowedMimeExtensions: string[];
  allowedExtensions: string[];
  minResolution: string;
}

export type AvatarGlobalConfig = {
  maxSize: number;
  rieltor: UserGroupAvatarConfig;
  owner: UserGroupAvatarConfig;
  agency: UserGroupAvatarConfig;
}

export type PaymentBonusGlobalConfig = {
  rieltor: PaymentBonusMap;
  agency: PaymentBonusMap;
}

export type UserGroupAvatarConfig = {
  allowedMimeExtensions: string[];
  allowedExtensions: string[];
}


export interface GlobalConfig {
  referralProgram?: ReferralProgram;
  olxImport?: OlxImportProgram;
  newRieltorBonus?: NewRieltorBonusMap;
  newOwnerBonus?: NewRieltorBonusMap;
  paymentBonuses?: PaymentBonusGlobalConfig;
  newRieltorBonusStandardDate?: string;
  newOwnerBonusStandardDate?: string;
  offers: OffersGlobalConfig;
  avatar: AvatarGlobalConfig;
}

export type OffersManagementFields = {
  [key in OffersManagementField]: string;
};

export type OlxImportManagementFields = {
  [key in OlxImportManagementField]: string;
};
export type AgencyFeedManagementFields = {
  [key in AgencyFeedManagementField]: string;
};


export type OffersManagementListingItem = {
  [key in OffersManagementField]: any;
};

export type OlxImportManagementListingItem = {
  [key in OlxImportManagementField]: any;
};
export type AgencyFeedManagementListingItem = {
  [key in AgencyFeedManagementField]: any;
};

export type ReferralsManagementListingItem = {
  [key in ReferralsManagementField]: any;
};

export enum LunRepresentability {
  Active = 1,
  Can = 2,
  Cannot = 0,
  CannotPersonalAccount = -1,
  CannotNotCanExclusiveAccepted = -2,
  CannotLunTop = -3,
}
export enum OfferStatus {
  Active = 10,
  Moderate = 1,
  ModerateBlocked = -1,
  Archived = -5,
  Deleted = -10,
  Lost = -50, // Deleted forever
  Partners = -30,
  Draft = -2,
  Deferred = -3,
  TempBanModerated = -20,
  TempBanWait = 20,
}

export enum PartnerMode {
  Partners = 'partners',
  Offers = 'offers',
  Deals = 'partner-deals',
}

export enum AgentsMode {
  Active = 'active',
  NotActive = 'inactive',
  Requested = 'requested',
  All = 'all',
}

export enum DealsMode {
  List = 'list',
  MonthReport = 'month-report',
  OfficeReport = 'office-report',
  PartnerDeals = 'partner-deals',
  AgencyDeals = 'agency-deals',
  AsnuDeals = 'asnu_deals',
  SfnuDeals = 'sfnu_deals',
  GlobalDeals = 'global_deals',
}

export enum OffersChipsMode {
  MyOffers = 'myOffers',
  Internet = 'internet',
  Agency = 'agency',
}

interface OffersManagementFilterItemTypeElement {
  label: string;
  value: ItemType;
  count: number;
}
interface OffersManagementFilterStatusElement {
  label: string;
  value: OfferStatus;
  count: number;
}
interface OffersManagementFilterRieltorElement {
  label: string;
  value: number;
}

export interface AgentsManagementFilters {
  id: string;
  office: number;
  username: string;
  cityName: string;
  phone: string;
}
export interface DealsManagementFilters {
  id: string;
  address: string;
  cityName: string;
  status: Array<Option<OfferStatus>>;
  operType: Array<Option<OperType>>;
  itemType: Array<Option<ItemType>>;
  rieltor: OffersManagementFilterRieltorElement[];
  offices: CommonValueLabel[];
}

export interface OffersManagementFilters {
  id: string;
  address: string;
  cityName: string;
  status: OffersManagementFilterStatusElement[];
  operType: Array<Option<OperType>>;
  rieltor: OffersManagementFilterRieltorElement[];
  itemType: Array<Option<ItemType>>;
}
export interface OlxImportManagementFilters {
  address: string;
  rooms: Array<Option<number>>;
  district: Array<Option<number>>;
  city: Array<Option<number>>;
  operType: Array<Option<OlxOperType>>;
  itemType: Array<Option<OlxItemType>>;
  isOwner: Array<Option<GeneralYesNo>>;
  hasRieltor: Array<Option<GeneralYesNo>>;
}
export interface AgencyFeedManagementFilters {
  address: string;
  rooms: Array<Option<number>>;
  district: Array<Option<number>>;
  city: Array<Option<number>>;
  operType: Array<Option<OlxOperType>>;
  itemType: Array<Option<OlxItemType>>;
  isOwner: Array<Option<GeneralYesNo>>;
  hasRieltor: Array<Option<GeneralYesNo>>;
}

interface OffersManagementViewStats {
  today: number;
  yesterday: number;
  week: number;
  allTime: number;
}
export enum PermissionReason {
  ReasonNotEnoughBalance =  1,
  ReasonFreeItemsOver = 2,
}
export interface PendingOffer {
  id: number;
  cost: number;
}
export interface UnbannedOffer {
  id: number;
  address: string;
  reasonId: PermissionReason;
}

export interface PartnerModeCounter {
  value: PartnerMode;
  label: string;
}
export interface AgentsModeCounter {
  value: AgentsMode;
  label: string;
}

export interface AgentsManagementListing {
  // pagination: Pagination;
  // fields: OffersManagementFields;
  items: UserInfo[];
  // filters: OffersManagementFilters;
  sort: string;
  total: number;
  active: number;
  modes: Option<AgentsModeCounter>[];
  currentMode: AgentsMode;
  pagination: Pagination;
  filters: AgentsManagementFilters;
}
export interface PartnersManagementListing {
  // pagination: Pagination;
  // fields: OffersManagementFields;
  items: UserInfo[];
  modes: Option<PartnerModeCounter>[];
  // filters: OffersManagementFilters;
  sort: string;
}

export interface ReferralsCounters {
  referralBonusSum: number;
  totalSuccessPoints: number;
  totalNeedPoints: number;
  successCount: number;
  totalCount: number;
}

export interface OffersManagementListing {
  pagination: Pagination;
  fields: OffersManagementFields;
  items: OffersManagementListingItem[];
  filters: OffersManagementFilters;
  sort: string;
  viewStats: OffersManagementViewStats;
  pendingOffers: PendingOffer[];
  unbannedOffers: UnbannedOffer[];
}

export interface ChipsCounters {
  agencyAdminDeals: number;
  rieltorDeals: number;
  rieltorAgencyDeals: number;
  rieltorPartnersDeals: number;
  afnuDeals: number;
  sfnuDeals: number;
  globalDeals: number;
}

export interface DealsManagementListing {
  pagination: Pagination;
  fields: OffersManagementFields;
  sort?: string;
  items: OffersManagementListingItem[];
  filters: DealsManagementFilters;
  viewStats: OffersManagementViewStats;
  pendingOffers: PendingOffer[];
}
export interface OlxImportManagementListing {
  pagination: Pagination;
  fields: OlxImportManagementFields;
  sort?: string;
  items: OlxImportManagementListingItem[];
  filters: OlxImportManagementFilters;
}
export interface AgencyFeedManagementListing {
  pagination: Pagination;
  fields: AgencyFeedManagementFields;
  sort?: string;
  items: AgencyFeedManagementListingItem[];
  filters: AgencyFeedManagementFilters;
}

export interface ReferralsManagementListing {
  pagination: Pagination;
  counters: ReferralsCounters;
  items: ReferralsManagementListingItem[];
}

export enum OfferAction {
  Activate = 'activate',
  Edit = 'edit',
  Delay = 'delay',
  Delete = 'delete',
  Trash = 'trash',
  ToDeals = 'to_deals',
  ToPartners = 'partners',
  Draft = 'draft',
  ForgetPendingOffers = 'forget-pending-offers',
  ForgetUnbannedOffers = 'forget-unbanned-offers',
  OffersCalc = 'calc',
  Refresh = 'refresh',
  Restore = 'restore',

  Pickup = 'pickup',
  ActivatePickup = 'activatePickup',
}

export enum AgentsListingSearchParam {
  Page = 'page',
  PageSize = 'limit',
  Sort = 'sort',
  Username = 'username',
  Office = 'office',
  CityName = 'cityName',
  Phone = 'phone',
  Mode = 'mode',
}

export enum PartnersListingSearchParam {
  Page = 'page',
  PageSize = 'limit',
  Sort = 'sort',
  Mode = 'mode',
  // Status = 'status',
  // Address = 'address',
  // Id = 'id',
  // OperType = 'operType',
  // Rieltor = 'rieltor',
  // ItemType = 'itemType',
  // CityName = 'cityName',
}

export enum OffersListingSearchParam {
  Page = 'page',
  PageSize = 'limit',
  Status = 'status',
  Address = 'address',
  Id = 'id',
  OperType = 'operType',
  Rieltor = 'rieltor',
  RieltorName = 'rieltorName',
  Office = 'office',
  ItemType = 'itemType',
  Mode = 'mode',
  CityName = 'cityName',
  DistrictName = 'districtName',
  Sort = 'sort',
}

export enum DealsListingSearchParam {
  Page = 'page',
  PageSize = 'limit',
  Sort = 'sort',
  Address = 'address',
  OperType = 'operType',
  Mode = 'mode',
  BBOX = 'bbox',
  Rooms = 'rooms',
  Currency = 'currency',
  Year = 'year',
  Id = 'id',
  Rieltor = 'rieltor',
  RieltorName = 'rieltorName',
  Office = 'office',
  ItemType = 'itemType',
  CityName = 'cityName',
  DistrictName = 'districtName',
}

export enum OlxImportListingSearchParam {
  Id = 'id',
  IsOwner = 'isOwner',
  HasRieltor = 'hasRieltor',
  Page = 'page',
  PageSize = 'limit',
  Sort = 'sort',
  Address = 'address',
  OperType = 'operType',
  ItemType = 'itemType',
  City = 'city',
  Rooms = 'rooms',
  District = 'district',
}
export enum AgencyFeedListingSearchParam {
  Id = 'id',
  IsOwner = 'isOwner',
  HasRieltor = 'hasRieltor',
  Page = 'page',
  PageSize = 'limit',
  Sort = 'sort',
  Address = 'address',
  OperType = 'operType',
  ItemType = 'itemType',
  City = 'city',
  Rooms = 'rooms',
  District = 'district',
}

export interface AgentsListingSearch {
  [AgentsListingSearchParam.Page]: number;
  [AgentsListingSearchParam.PageSize]: number;
  [AgentsListingSearchParam.Sort]: string;
  [AgentsListingSearchParam.Mode]: string;
}

export interface PartnersListingSearch {
  [PartnersListingSearchParam.Page]: number;
  [PartnersListingSearchParam.PageSize]: number;
  [PartnersListingSearchParam.Sort]: string;
  [PartnersListingSearchParam.Mode]: string;
}

export enum ReferralsListingSearchParam {
  Page = 'page',
  PageSize = 'limit',
}

export interface OffersListingSearch {
  [OffersListingSearchParam.Page]: number;
  [OffersListingSearchParam.PageSize]: number;
  [OffersListingSearchParam.Status]: OfferStatus;
  [OffersListingSearchParam.Address]: string;
  [OffersListingSearchParam.CityName]: string;
  [OffersListingSearchParam.DistrictName]: string;
  [OffersListingSearchParam.Id]: number;
  [OffersListingSearchParam.OperType]: OperType;
  [OffersListingSearchParam.Rieltor]: number;
  [OffersListingSearchParam.RieltorName]: string;
  [OffersListingSearchParam.ItemType]: ItemType;
  [OffersListingSearchParam.Sort]: string;
}

export interface DealsListingSearch {
  [DealsListingSearchParam.Page]: number;
  [DealsListingSearchParam.PageSize]: number;
  [DealsListingSearchParam.Address]: string;
  [DealsListingSearchParam.CityName]: string;
  [DealsListingSearchParam.DistrictName]: string;
  [DealsListingSearchParam.Id]: number;
  [OffersListingSearchParam.Rieltor]: number;
  [OffersListingSearchParam.Office]: number;
  [DealsListingSearchParam.ItemType]: ItemType;
  [DealsListingSearchParam.OperType]: OperType;
  [DealsListingSearchParam.Mode]: string;
  [DealsListingSearchParam.BBOX]: any;
  [DealsListingSearchParam.Currency]: number;
  [DealsListingSearchParam.Year]: number;
  [DealsListingSearchParam.Rooms]: number;
}

export interface OlxImportListingSearch {
  [OlxImportListingSearchParam.IsOwner]: number;
  [OlxImportListingSearchParam.HasRieltor]: string;
  [OlxImportListingSearchParam.Id]: number;
  [OlxImportListingSearchParam.Page]: number;
  [OlxImportListingSearchParam.PageSize]: number;
  [OlxImportListingSearchParam.Address]: string;
  [OlxImportListingSearchParam.City]: number;
  [OlxImportListingSearchParam.Rooms]: number;
  [OlxImportListingSearchParam.District]: number;
  [OlxImportListingSearchParam.ItemType]: ItemType;
  [OlxImportListingSearchParam.OperType]: OperType;
}

export interface AgencyFeedListingSearch {
  [AgencyFeedListingSearchParam.IsOwner]: number;
  [AgencyFeedListingSearchParam.HasRieltor]: string;
  [AgencyFeedListingSearchParam.Id]: number;
  [AgencyFeedListingSearchParam.Page]: number;
  [AgencyFeedListingSearchParam.PageSize]: number;
  [AgencyFeedListingSearchParam.Address]: string;
  [AgencyFeedListingSearchParam.City]: number;
  [AgencyFeedListingSearchParam.Rooms]: number;
  [AgencyFeedListingSearchParam.District]: number;
  [AgencyFeedListingSearchParam.ItemType]: ItemType;
  [AgencyFeedListingSearchParam.OperType]: OperType;
}

export interface ReferralsListingSearch {
  [DealsListingSearchParam.Page]: number;
  [DealsListingSearchParam.PageSize]: number;
}

export interface Pickup {
  rentaItemId: number;
  quickly: number;
  autoupdate: boolean;
  birdPremium: boolean;
  birdPremiumTemp: boolean;
  lunTop: boolean;
  lunTopTemp: boolean;
  rate: number;
  cityPlace?: number;
  realCityPlace?: number;
  realRegionPlace?: number;
  regionPlaceMode?: boolean;
  cityName?: string;
  cityNamePrep?: string;
  maxPlace?: number;
  placeScale: { [key: number]: number };
  placeScaleRegion: { [key: number]: number };
  services: string[];
  status?: OfferStatus;
}

export interface PickupsStats {
  totalOffers: number;
  pickupsBase: number;
  pickupsOver: number;
  pickedUpCount: number;
  premiumCount: number;
  autoRefreshCount: number;
  birdTopAvailOffers: number;
  birdTopCount: number;
  birdSelectAvailOffers: number;
  birdSelectCount: number;
  lunTopAvailCount: number; // FIXME not implemented yet
  lunTopCount: number;
}

export interface PickupOptions {
  id: number;
  form: Pickup;
  params: {
    costAutoupdate: number;
    costBirdPremium: number;
    costLunTop: number;
    oldCostLunTop: number;
    costBirdPremiumOld?: number;
    costQuickly: number;
    minRentaRate: number;
    cashbackBirdSelectPercent: number;
  };
  geoName: string;
  dropdown: Option[];
  isLunPublicationAvailable: boolean;
  isLunPublicationEnabled: boolean;
  isLunPublicationDisabledReason: 'bankid' | 'viber' | 'without_commission';

  isBirdPublicationAvailable: boolean;
  isBirdPublicationEnabled: boolean;
  isBirdPublicationDisabledReason: 'bankid' | 'telegram';

  isBirdPremiumAvailable: boolean;
  isBirdSelectAvailable: boolean;

  isLunTopPublicationAvailable: boolean;
  isLunTopPublicationEnabled: boolean;
  isLunTopPublicationDisabledReason: string;

  isLunTopAvailable: boolean;


  representabilityRate: number;
}

export interface TariffEntity {
  id: string;
  dropdown: Option[];
  dailyCost: number;
  totalCost: number;
  minPayment: number;
  rate?: number;
  selected: boolean;
  canPublish: boolean;
  quickly?: number;
}

export interface PickupCoins {
  coins: number;
  cityPlace: number;
  maxPlace: number;
  minPlace: number;
}

export interface PickupPlace {
  maxPlace: number;
  minPlace: number;
}

export interface CalcCashbackResult {
  value: number;
}

export interface OfferReportResult {
  title: string;
  published:{
    rieltor: string;
    lun: string;
    bird: string;
    flatfy: string;
    birdUrl: string;
    flatfyUrl: string;
    lunUrl: string;
    rieltorUrl: string;
  },
  ads: {
    autoupdate: boolean;
    birdPremium: boolean;
    birdSelect: boolean;
    quickly: boolean;
    posCity: number;
    posTotal: number;
    birdPremiumAvailable: boolean;
    birdSelectAvailable: boolean;
    lunTop: boolean;
    lunTopAvailable: boolean;

  },
  stats: {
    published: string;
    viewsTotal: number;
    viewsWeek: number;
    viberTotal: number;
    viberWeek: number;
    contactsTotal: number;
    contactsWeek: number;
    favoritesTotal: number;
    favoritesWeek: number;
    spent: number;
  },
  viewsGraph: any[]
}

export enum ExclusiveStatus {
  New = 0,
  Accepted = 1,
  Hold = 2,
  Deleted = 4,
  VerifiedAccepted = 5,
}

export enum FinanceOperationSubType {
  Inbound = 1,
  Outbound = -1,
}

export interface FinanceOperationDetails {
  date: string;
  subType: FinanceOperationSubType;
  remark: string;
  val: number;
}

export interface FinanceOperationsListing {
  pagination: Pagination;
  items: FinanceOperationDetails[];
}

export enum FinanceOperationsListingSearchParam {
  Page = 'page',
  PageSize = 'limit',
}

export interface FinanceOperationsListingSearch {
  page: number;
  limit: number;
}

export interface ProfileStats {
  counters: {
    sale: {
      flats: number;
      rooms: number;
      houses: number;
      commercials: number;
      areas: number;
    };
    rent: {
      flats: number;
      rooms: number;
      houses: number;
      commercials: number;
      areas: number;
    };
    rentDaily: {
      flats: number;
      rooms: number;
      houses: number;
    };
  };
  viewCounters: {
    today: number;
    yesterday: number;
    week: number;
    allTime: number;
  };
}

export interface UserAgenciesAutocompleteOption {
  firmId: number;
  username: string;
}

export interface OfficeLatLng {
  lat: number;
  lng: number;
}

export interface UserAgencyOfficeList {
  id: number;
  address: string;
  name: string;
}
export interface UserAgencyOffice {
  id: number;
  street: string | Option<number>;
  district: Nullable<Option>;
  img: string;
  house: string;
  phones: string[];
}
export interface UserAgencyOfficePost {
  streetId?: number;
  streetNameUnfound?: string;
  districtId: number;
  phones: string[];
  img: string;
  house: string;
}

export interface AgencyProfileInfoFormData {
  username: string;
  avatar: string;
  city: Option;
  street: Nullable<Option>;
  about: string;
  address: string;
  startedAt?: string;
  streetNameUnfound: Nullable<string>;
  houseNameUnfound: Nullable<string>;
  specializations: Array<{itemType: number; operType: number}>;

  officeImg: string;
  officeDistrict: Option<number>;
  officeCoords: OfficeLatLng;
}

export interface RealtorProfileInfoFormData {
  username: string;
  avatar: string;
  city: Option;
  about: string;
  speakEnglish: boolean;
  startedAt?: string;
  specializations: Array<{itemType: number; operType: number}>;
}

export interface OwnerProfileInfoFormData {
  username: string;
  avatar: string;
  city: Option;
  inn: string;
}

export interface SocialSettingsFormData {
  facebook: string;
  instagram: string;
  youtube: string;
  tiktok: string;
  website: string;
}

export type ProfileInfoFormData =
  | AgencyProfileInfoFormData
  | RealtorProfileInfoFormData
  | OwnerProfileInfoFormData;

export interface OwnerProfileInfoPayload {
  username: string;
  cityId: number;
  avatar: string;
  inn: string;
}

export interface RealtorProfileInfoPayload {
  username: string;
  avatar: string;
  website: string;
  cityId: number;
  about: string;
  speakEnglish: boolean;
  startedAt?: string;
  specializations: Array<{itemType: number; operType: number}>;
}

export interface AgencyProfileInfoPayload {
  username: string;
  avatar: string;
  website: string;
  cityId: number;
  streetId: number | null;
  streetNameUnfound: string | null;
  houseNameUnfound: string | null;
  address: string | null;
  about: string;
  startedAt?: string;
  specializations: Array<{itemType: number; operType: number}>;
}

export interface GiftAddressPayload {
  cityId: number;
  novaposhtaWarehouseId: number;
  novaposhtaWarehouseDescription: string;
  phone: string;
}
export interface GiftAddressInfo {
  city: CommonValueLabel;
  novaposhtaWarehouse: CommonValueLabel;
  phone: string;
  phones: CommonValueLabel[];
}

export interface OfferChipsInfo {
  offers: number;
  internet: number;
}

export type ProfileInfoPayload =
  | OwnerProfileInfoPayload
  | RealtorProfileInfoPayload
  | AgencyProfileInfoPayload;

export type AnStyles = {
  textColor: string;
  img: string;
};

export type OffersViewsStats = Array<{
  date: string;
  count: number;
  clicks: number;
  message: number;
  favorites: number;
  x: string;
}>;

export type DealsStats = Array<{
  date: string;
  sales: number;
  rents: number;
  debits: number;
  x: string;
}>;

export type OfferReportViewStat = Array<{
  date: string;
  count: number;
  x: string;
}>;

export interface RentaStats {
  views: number;
  contactClicks: number;
  sendMessage: number;
}

export interface MultiplePickupInfo {
  dailyPayment: number;
  alreadyPayed: number;
}

export enum LocalStorageKeys {
  MultiplePickupAlertVisible = 'mpav',
  NoPhotoAlertClosed = 'npac',
  UIPriceCurrency = 'upc',
  DealsFirstUse = 'dfu',
  UserAlert = 'ual_',
  LevelStatusAlert = 'lsa_',
  ReferralBanner = 'referral',
  NewUserAlertClosed = 'nua',
  ReferralCount = 'referralCount',
  OlxAlert = 'olx_',
  RegisterUtmParams = 'register_utms',
  Registration = 'registration',
  LoginUtmParams = 'login_utms',
  NewPartners = 'new_partners',
  NewPartnersOffers = 'new_partners_offers',
  RieltorManifest = 'rieltor_manifest',
  OlxImportFirstUse = 'olx_import_first_use',
  OlxImportAlert = 'olx_import_alert',
}

export enum SessionStorageKey {
  FreeOffersAcceptedButtonHidden = 'foabh',
  FreeOffersAcceptedButtonHiddenPickup = 'foabhpickup',
  LastOfferEdit = 'lastofferedit',
  LastLinkBeforeLogin = 'lastlinkbeforelogin',
}

export enum AlertType {
  ApiError = 'api-error',
  EmailConfirmed = 'email-confirmed',
  PaymentSuccessful = 'payment-successful',
  PaymentGiftSuccessful = 'payment-gift-successful',
  PaymentProcessing = 'payment-processing',
  PaymentWaitForCash = 'payment-wait-for-cash',
  PaymentWaitCommon = 'payment-wait',
  PaymentFailed = 'payment-failed',
  BankidAlreadyVerifiedAnotherUser = 'bankid-already-verified-another-user',
  BankidAlreadyVerifiedAnotherUserLimit = 'bankid-already-verified-another-user-limit',
  BankidAlreadyVerifiedUserDeleted = 'bankid-already-verified-user_deleted',
  VerificationNotMatch = 'verification-not-match',
  BankidAlreadyVerified = 'bankid-already-verified',
  BankidGeneralError = 'bankid-general-error',
  BankidErrorRedirect = 'bankid-error-redirect',
  BankidVerifiedSuccess = 'bankid-verified-success',
  BankidVerifiedSuccessNewRieltor = 'bankid-verified-success-new-rieltor',
  BankidVerifiedSuccessNewRieltorNoBonus = 'bankid-verified-success-new-rieltor-no-bonus',
  DiiaVerifiedSuccess = 'diia-verified-success',
  DiiaVerifiedSuccessNewRieltor = 'diia-verified-success-new-rieltor',
  DiiaVerifiedSuccessNewRieltorNoBonus = 'diia-verified-success-new-rieltor-no-bonus',
  DiiaVerifiedAlreadyUsed = 'diia-verified-already_used',
  DiiaVerifiedUserDeleted = 'diia-verified-user_deleted',
  BankidUnknownError = 'bankid-unknown-error',
  ReferralsRegisterOnly = 'referrals-register-only',
}

export interface AgentSuggestion {
  id: number;
  username: string;
  avatar: string;
  phones: Phone[];
  viberPhone: Phone;
  domain: string;
}

export interface OfferAgentsSuggestionsInfo {
  offer: {
    id: number;
    operType: OperType;
    itemType: ItemType;
    cityNamePrepositional: string;
  };
  agents: AgentSuggestion[];
}

export enum UIPriceCurrency {
  Coins = 'coins',
  UAH = 'uah',
  USD = 'usd',
  EUR = 'eur',
}

export const COINS_TO_CURRENCY_EXCHANGE_RATE: {
  [K in UIPriceCurrency]: number;
} = {
  [UIPriceCurrency.Coins]: 1,
  [UIPriceCurrency.UAH]: 0.1,
  [UIPriceCurrency.USD]: 0,
  [UIPriceCurrency.EUR]: 0,
};

export interface AgentsMonthReportItem {
  month: string;
  counts: { [key: number]: number }[]
  count:  number;
}
export interface AgentsMonthReportRieltorItem {
  label: string;
  value: number;
  office?: CommonValueLabel;
  count: number;
  avatar: string;
}
export interface AgentsMonthReport {
  items: AgentsMonthReportItem[];
  rieltors: AgentsMonthReportRieltorItem[];
  filters: {
    rieltors: CommonValueLabel[];
    offices: CommonValueLabel[];
  },
  total: number;
}
export interface OfficeMonthReportOfficeTotalOperItem {
  id: number;
  value: number;
  label: string;
}
export interface OfficeMonthReportOfficeTotalItem{
  total: number;
  opers: OfficeMonthReportOfficeTotalOperItem[];
  label: string;
  value: number;
}
export interface OfficeMonthReportOfficeItem {
  total: number;
  opers: { [key: number]: number };
  label?: string;
  value: number;
}
export interface OfficeMonthReportOfficeCounterItem {
  [key: number]: OfficeMonthReportOfficeItem;
}
export interface OfficeMonthReportItem {
  month: string;
  total: number;

  offices: OfficeMonthReportOfficeCounterItem[];
}
export interface OfficeMonthReport {
  items: OfficeMonthReportItem[];
  offices: CommonValueLabel[];
  officeCounters: OfficeMonthReportOfficeTotalItem[];
}


export interface ReasonOfUnpublish {
  id: number;
  name: string;
}

export interface CommonIdName {
  id: number;
  name: string;
}

export interface CommonValueLabel {
  value: number;
  label: string;
}

export interface ReferralInfo {
  username: string,
  avatar: string,
}

export interface CommonUploadInfo {
  imageKey: string;
}

export enum NormalizedRoutes {
  Login = '/login',
  Dashboard = '/dashboard',
  OffersCreate = '/offers/create',
  ProfileSummary = '/profile/summary',
  ProfileAgents = '/profile/agents',
  ProfileStats = '/profile/stats',
  Payment = '/payment',
  OffersManagement = '/offers/management',
  DealsManagement = '/deals',
  OlxMigration = '/olx-migration',
}

export type BuildingType = 'new_building' | 'cottage';
