import {createMuiTheme} from '@material-ui/core';

export const rieltor = {
  fontFamily: 'Inter, sans-serif',
  mainGradient: 'linear-gradient(180deg, #81F 0%, #DA48C5 100%)',
  whiteTransparent: 'rgba(255, 255, 255, 0.6)',
  gray: '#4C5B6B',
  gray4: '#979FA9',
  grayFill: '#F3F4F6',
  grayLines: '#E9ECF1',
  grayLight: '#FAFAFC',
  purple: '#81F',
  purplePushed: '#701BCE',
  yellow: '#FFCB2B',
  lightViolet: '#F0F1FA',
  green: '#00C070',
  greenLight: '#E8FDF7',
  orange: '#D57D73',
  orangeLight: '#F9EEED',

  labelMagenta: '#CA53C0',
  labelMagentaLight: '#FFF2FE',
  labelYellow: '#E5B53D',
  labelYellowLight: '#FFF5D4',
  labelBlue: '#4181C5',
  labelBlueLight: '#E3EDFF',
  labelOrange: '#F37549',
  labelOrangeLight: '#FFECE5',

  chartBlue: '#3366cc',
  chartRed: '#dc3912',
  chartOrange: '#ff9900',
  chartGreen: '#109618',
  chartBirdColor: '#000050',
  chartLunTopColor: '#ff9900',
  chartPremium: '#81F',
};

const theme = createMuiTheme({
  palette: {
    primary: {
      main: rieltor.purple,
    },
  },
  shape: {
    borderRadius: 4,
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        // override the pseudo-classes
        // '.Mui-disabled': { opacity: .5 }
      }
    },
    MuiButton: {
      label: {
        textTransform: 'none',
      },
      contained: {
        boxShadow: 'none',
        '&:active': {
          boxShadow: 'none',
        },
      },
    },
  },
});

export {theme};
