import React, {FunctionComponent, useMemo} from 'react';
import {Box, Button, makeStyles, Theme, Typography} from '@material-ui/core';
import {FormattedMessage as FM, useIntl} from "react-intl";
import {RoleId, RoleName, UserInfo, UserLevelStatus} from "../../../../common/types";
import {useSelector} from "react-redux";
import {paymentBonusesSelector} from "../../../../store/global/selectors";
import {getUserTariff} from "../../../../utils";
import RouterLink from "../../RouterLink";
import {makeTrackable} from "../../../../modules/ga";
const TrackableButton = makeTrackable(Button);

const useStyles = makeStyles<Theme>(theme => ({
  alert: {
    alignItems: 'baseline',
    marginBottom: theme.spacing(1),
    '&:first-child': {
      marginTop: theme.spacing(2),
    },
    '&:last-child': {
      marginBottom: theme.spacing(2),
    },
  },
  root: {
    borderRadius: 4,
    background: 'linear-gradient(180deg, #7D1FF5 0%, #DA48C5 99.98%)',
    display: 'grid',
    //gridTemplateColumns: '25% 38%',
    gridTemplateColumns: 'auto calc(474px + 8%)',
    paddingRight: '8%',
    justifyContent: 'space-between',
    position: 'relative',
    overflow: 'hidden',
    [theme.breakpoints.down('md')]: {
      display: 'block',
      paddingRight: 0,
    },
    [theme.breakpoints.up('md')]: {
      "&.-new-user-bonus-alert": {
        paddingRight: '2%',
        "& $alertImgWr img": {
          width: 400,
          height: 242
        }
      }
    }
  },
  alertClose:{
    position: 'absolute',
    top: 11,
    right: 15,
    width: 26,
    height: 26,
    zIndex: 1,
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    transition: 'background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    [theme.breakpoints.down('xs')]: {
      top: 0,
      right: 0,
    },
    '&:hover':{
      backgroundColor: 'rgba(0, 0, 0, 0.08)'
    },
    '& svg':{
      width: 16,
      height: 16
    }
  },
  alertInfo:{
    padding: '32px 29px 25px',
    [theme.breakpoints.down('xs')]: {
      padding: '18px 19px 13px',
    },
  },
  alertTitle:{
    color: '#FFF',
    fontSize: 18,
    fontWeight: 700,
    lineHeight: '40px',
    marginBottom: 13,
    whiteSpace: 'nowrap',
    [theme.breakpoints.down('md')]: {
      whiteSpace: 'normal',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 20,
      lineHeight: '20px',
      marginBottom: 20,
    },
    '& span':{
      color: '#FFD700'
    }
  },
  alertImgWr:{
    position: 'relative',
    marginLeft: 'auto'
  },
  alertImg:{
    height: '100%',
    display: 'block',
    objectFit: 'cover',
    objectPosition: 'left',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  alertImgMob:{
    display: 'none',
    [theme.breakpoints.down('md')]: {
      display: 'block',
      width: '344px',
      margin: 'auto'
    },
  },
  alertText:{
    // color: '#FFF',
    fontSize: 12,
    lineHeight: '20px',
    marginBottom: 15,
    // whiteSpace: 'pre-wrap',
    // '& strong':{
    //   color: '#FFCB2B',
    //   fontWeight: 700,
    // },
    // [theme.breakpoints.down('xs')]: {
    //   marginBottom: 30,
    // },
    // "& span": {
    //   color: '#FFCB2B',
    //   fontWeight: 800,
    // },
    color: '#FFF',
    '& .username': {
      textDecoration: 'underline',
      color: '#FFF',
    },
    '& .coins': {
      color: '#FFC700',
      fontWeight: 700,
    },
    '& p:first-child': {
      fontWeight: 700,
      // fontSize: 12,
    }
  },
  alertLink:{
    color: '#FFF',
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: '-0.14px',
    display: 'inline-flex',
    alignItems: 'center',
    borderRadius: 3,
    border: '1px solid #FFF',
    padding: '5px 28px',
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
    },
    '& svg':{
      marginLeft: 6,
      width: 8
    }
  },
}));

interface RieltorGiftAlertAlertProps {
  user: UserInfo;
  handleUserAlertClose: (alertId: string) => void;
  showedAlerts: {[k: string]: boolean} | null;
  isAlertStatusVisible: (alertId: string) => boolean;
  alertId: string;
  text: string;
}


const RieltorGiftAlert: FunctionComponent<RieltorGiftAlertAlertProps> = ({
                                                                          user,
                                                                          handleUserAlertClose,
  showedAlerts, isAlertStatusVisible,alertId,text
                                                                        }: RieltorGiftAlertAlertProps) => {
  const classes = useStyles();
  const intl = useIntl();
  const {formatMessage: fm} = intl;

  const paymentBonuses = useSelector(paymentBonusesSelector);
  if (!user) return null;
  if (!user.levelStatus) return null;

  // const roleId = user.roleId;
  // const regex = /^\<div\>(.*)\<\/div\>$/gm;
  // const matches = regex.exec(text);
  // const newText = matches?.[0] || '';

  return (
    <>
      <Box className={classes.alert}>
        <Box className={classes.root}>
          <Box className={classes.alertClose} onClick={() => handleUserAlertClose(alertId.toString())}>
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
              <path
                d="M16 1.61143L14.3886 0L8 6.38857L1.61143 0L0 1.61143L6.38857 8L0 14.3886L1.61143 16L8 9.61143L14.3886 16L16 14.3886L9.61143 8L16 1.61143Z"
                fill="white"/>
            </svg>
          </Box>
          <Box className={classes.alertInfo}>
            <Box className={classes.alertTitle}>
              Вам зробили подарунок!
            </Box>
            <Box className={classes.alertText} dangerouslySetInnerHTML={{__html: text}}/>
          </Box>
          <Box className={classes.alertImgWr}>
            <img
                className={classes.alertImg}
                src={require(`../images/alert_rieltor_gift.png`)}
                alt=""
                srcSet={`${require(`../images/alert_rieltor_gift.png`)} 1x, ${require(`../images/alert_rieltor_gift@2x.png`)} 2x`}
            /><img
                className={classes.alertImgMob}
                src={require(`../images/alert_rieltor_gift_mob.png`)}
                alt=""
                srcSet={`${require(`../images/alert_rieltor_gift_mob.png`)} 1x, ${require(`../images/alert_rieltor_gift_mob@2x.png`)} 2x`}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default RieltorGiftAlert;