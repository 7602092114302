import {AppState} from '../../index';

export const OFFERS_DIALOG_NOT_SELECTED = 'offers_dialog_not_selected';
export const OFFERS_DIALOG_PICKUP = 'pickup';
export const OFFERS_DIALOG_PARTNER = 'partners';
export const OFFERS_DIALOG_EDIT = 'edit';
export const OFFERS_DIALOG_REFRESH = 'refresh';
export const OFFERS_DIALOG_DRAFT = 'draft';
export const OFFERS_DIALOG_DELETE = 'delete';
export const OFFERS_DIALOG_LOST = 'lost';
export const OFFERS_DIALOG_DEALS = 'to_deals';
export const OFFERS_DIALOG_REPORT = 'report';
export const OFFERS_DIALOG_COPY = 'copy';
export const OFFERS_DIALOG_MOBILE_MENU = 'mobile_menu';

const OFFERS_DIALOG_NAME = '@offers/dialog-by-name';
const OFFERS_FORCE_RELOAD = '@offers/force_reload';

interface OffersDialogOpen {
  type: typeof OFFERS_DIALOG_NAME;
  payload: {
    dialogName: string,
    dialogParent: string,
  };
}

interface OffersForceReload {
  type: typeof OFFERS_FORCE_RELOAD;
}

interface State {
  dialogName: null | string;
  dialogParent: null | string;
  forceReload: boolean;
}

const initState: State = {
  dialogName: null,
  dialogParent: null,
  forceReload: false,
};

type ActionDialogs =
  OffersDialogOpen | OffersForceReload;

export const actionDialogsReducer = (
  state: State = initState,
  action: ActionDialogs,
) => {
  switch (action.type) {
    case OFFERS_DIALOG_NAME:
      return {
        ...state,
        dialogName: action.payload.dialogName,
        dialogParent: action.payload.dialogParent,
      };
    case OFFERS_FORCE_RELOAD:
      return {
        ...state,
        forceReload: !state.forceReload,
      };

    default:
      return state;
  }
};

export const openDialog = (name: string, parent?: string) => {
  return {
    type: OFFERS_DIALOG_NAME,
    payload: {
      dialogName: name,
      dialogParent: parent,
    },
  };
};

export const closeDialog = () => {
  return {
    type: OFFERS_DIALOG_NAME,
    payload: {
      dialogName: null,
      dialogParent: null,
    },
  };
};

export const forceReload = () => {
  return {
    type: OFFERS_FORCE_RELOAD,
  };
};

export const getDialogName = (state: AppState) => state.offers.dialogs.dialogName;

export const getDialogParent = (state: AppState) => state.offers.dialogs.dialogParent;

export const getForceReload = (state: AppState) => state.offers.dialogs.forceReload;
