import {OffersManagementListing, OffersManagementListingItem} from 'common/types';
import {
  FETCH_OFFERS_MANAGEMENT_LISTING_FAILURE,
  FETCH_OFFERS_MANAGEMENT_LISTING_REQUEST,
  FETCH_OFFERS_MANAGEMENT_LISTING_SUCCESS,
  FetchOffersManagementListingAction,
  FetchOffersManagementListingFailureAction,
  FetchOffersManagementListingSuccessAction,
  OFFER_SELECT,
  OfferSelectAction,
} from './types';

export const fetchOffersManagementListing = (
  params: any
): FetchOffersManagementListingAction => ({
  type: FETCH_OFFERS_MANAGEMENT_LISTING_REQUEST,
  payload: {
    params,
  },
});

export const fetchOffersManagementListingSuccess = (
  data: OffersManagementListing
): FetchOffersManagementListingSuccessAction => ({
  type: FETCH_OFFERS_MANAGEMENT_LISTING_SUCCESS,
  payload: {
    data,
  },
});

export const fetchOffersManagementListingFailure = (
  error: Error
): FetchOffersManagementListingFailureAction => ({
  type: FETCH_OFFERS_MANAGEMENT_LISTING_FAILURE,
  error,
});

export const selectOffer = (
  offer: null|OffersManagementListingItem
): OfferSelectAction => ({
  type: OFFER_SELECT,
  payload: {
    offer
  }
});
